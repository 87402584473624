*{
    box-sizing: border-box;
}

.gpt3__cta{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
    border: 1px solid #000000;
}

.gpt3__cta-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #0E0E0E;
}

.gpt3__cta-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    color: #000000;
}

.gpt3__cta-button button{
    width: 189.14px;
    height: 59.4px;
    color: #fff;
    background: #000;
    font-family: var(--font-family);
    border-radius: 40px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-button{
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta{
        margin: 4rem 2rem;
    }
    
    .gpt3__cta-content p{
        font-size: 10px;
    }

    .gpt3__cta-content h3{
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-content{
       font-size: 14px;
       line-height: 28px; 
    }
}